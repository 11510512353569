import { trackError } from '@local/metrics';
import {
    COLLAPSE_DRAWER_WIDTH_UNITS,
    DRAWER_WIDTH_UNITS,
    Notification,
    UNIT,
} from '@local/web-design-system';
import { Grid, Snackbar } from '@mui/material';
import autobind from 'autobind-decorator';
import classnames from 'classnames';
import { injectIntl } from 'react-intl';
import { compose } from 'redux';
import { withStyles } from 'src/styles/utils';
import * as types from 'src/types';
import { isValidNumber } from 'src/utilities';
import { hasBit } from 'src/utilities/bitwise-helpers';
import React from 'react';

import { connectToState } from './NotificationsContext.connect';
import { NOTIFICATION_AUTO_REMOVING_DEFAULT_TIMEOUT_MS } from './NotificationsContext.constants';
import { i18n } from './NotificationsContext.i18n';
import { styles } from './NotificationsContext.styles';
import * as NotificationsContextTypes from './NotificationsContext.types';
import { SnackbarNotificationData } from './SnackbarNotificationData';

const NotificationsContext =
    React.createContext<NotificationsContextTypes.NotificationsContextValue>({
        showSnackbarNotification: () => {},
        closeSnackbarNotification: () => {},
        isSnackbarNotificationActive: () => false,
        clearNotifications: () => {},
    });

export class NotificationsContextProviderBase extends React.Component<
    NotificationsContextTypes.NotificationsContextProviderProps,
    NotificationsContextTypes.NotificationsContextProviderState
> {
    constructor(props: Readonly<NotificationsContextTypes.NotificationsContextProviderProps>) {
        super(props);

        this.state = {
            activeSnackbarNotificationsDataList: [],
        };
    }

    componentDidUpdate(prevProps: NotificationsContextTypes.NotificationsContextProviderProps) {
        const { intl, notificationList, clearSnackbarMessages } = this.props;
        if (notificationList.length > 0 && prevProps.notificationList !== notificationList) {
            notificationList.forEach((notification) => {
                const message =
                    notification.i18nKey && notification.i18nKey in i18n
                        ? intl.formatMessage(
                              (i18n as any)[notification.i18nKey],
                              notification.i18nValues || {},
                          )
                        : notification.message;
                if (message) {
                    this.showSnackbarNotification({
                        descriptor: notification.descriptor,
                        type: notification.type,
                        message,
                    });
                }
            });

            const descriptors = notificationList.map((notification) => notification.descriptor);
            clearSnackbarMessages(descriptors);
        }
    }

    get notificationsMaxIndex(): number {
        const { activeSnackbarNotificationsDataList: list } = this.state;

        return list.reduce((maxIndex, data) => (data.index > maxIndex ? data.index : maxIndex), -1);
    }

    get autoRemovingTimeoutMs(): number {
        const { autoRemovingTimeoutMs: timeout } = this.props;

        return timeout && isValidNumber(timeout) && timeout >= 0
            ? +timeout
            : NOTIFICATION_AUTO_REMOVING_DEFAULT_TIMEOUT_MS;
    }

    get notificationsLimit(): number {
        const { notificationsLimit } = this.props;

        return notificationsLimit && isValidNumber(notificationsLimit) && notificationsLimit > 0
            ? +notificationsLimit
            : Number.POSITIVE_INFINITY;
    }

    get needToFadeOutOnRemoving(): boolean {
        const { fadingOutOnAutoRemoving } = this.props;

        return !!fadingOutOnAutoRemoving;
    }

    getNotificationOpacity(index: number): number {
        const indexRatio = 1.0 / this.notificationsLimit;
        return 1.0 - index * indexRatio;
    }

    @autobind
    showSnackbarNotification(arg: NotificationsContextTypes.SnackbarNotificationShowingArg) {
        let { activeSnackbarNotificationsDataList: list } = this.state;
        const { removingStrategy } = this.props;
        const { descriptor, message, timeoutMs = this.autoRemovingTimeoutMs } = arg;

        const notificationExists = list.some(
            (notificationItem) =>
                notificationItem.descriptor === descriptor && notificationItem.message === message,
        );

        if (notificationExists) {
            return;
        }

        const nextDataIndex = this.notificationsMaxIndex + 1;
        const data = new SnackbarNotificationData(arg, nextDataIndex);

        const removeLast = hasBit(
            removingStrategy,
            NotificationsContextTypes.NotificationsRemovingStrategy.AUTO_REMOVE_LAST,
        );
        if (removeLast) {
            data.registerForRemoving(timeoutMs)
                .then(() => {
                    this.closeSnackbarNotification(data.descriptor, data.index);
                })
                .catch((error) => {
                    trackError(error.message);
                    // eslint-disable-next-line no-console
                    console.warn(error.message);
                });
        }

        const removeExceptLast = hasBit(
            removingStrategy,
            NotificationsContextTypes.NotificationsRemovingStrategy.AUTO_REMOVE_EXCEPT_LAST,
        );
        if (removeExceptLast) {
            list.forEach((data) => {
                if (!data.isPendingForRemoving) {
                    data.registerForRemoving(timeoutMs)
                        .then(() => {
                            this.closeSnackbarNotification(data.descriptor, data.index);
                        })
                        .catch((error) => {
                            trackError(error.message);
                            // eslint-disable-next-line no-console
                            console.warn(error.message);
                        });
                }
            });
        }

        list.unshift(data);

        const notificationsLimit = this.notificationsLimit;
        const limitExcess = list.length - notificationsLimit;
        if (limitExcess > 0) {
            list = list.slice(0, notificationsLimit);
        }

        this.setState({
            activeSnackbarNotificationsDataList: list,
        });
    }

    @autobind
    closeSnackbarNotification(
        descriptor: NotificationsContextTypes.SnackbarNotificationDescriptor,
        index: types.Nullable<number> = null,
    ) {
        const { activeSnackbarNotificationsDataList: list } = this.state;

        const nextList: SnackbarNotificationData[] = [];
        list.forEach((data) => {
            if (data.checkIfMatchTarget(descriptor, index)) {
                data.handleClose();
            } else if (!data.isClosed) {
                nextList.push(data);
            }
        });

        if (nextList.length === list.length) {
            return;
        }

        this.setState({
            activeSnackbarNotificationsDataList: nextList,
        });
    }

    @autobind
    isSnackbarNotificationActive(
        descriptor: NotificationsContextTypes.SnackbarNotificationDescriptor,
        index: types.Nullable<number> = null,
    ): boolean {
        const { activeSnackbarNotificationsDataList: list } = this.state;

        return !!list.find((data) => data.checkIfMatchTarget(descriptor, index));
    }

    @autobind
    clearNotifications() {
        this.setState({
            activeSnackbarNotificationsDataList: [],
        });
    }

    render() {
        const { children, classes, linearFadingOut, showingType, isNavDrawerOpen } = this.props;
        const { activeSnackbarNotificationsDataList: list } = this.state;

        const providerValue: NotificationsContextTypes.NotificationsContextValue = {
            showSnackbarNotification: this.showSnackbarNotification,
            closeSnackbarNotification: this.closeSnackbarNotification,
            isSnackbarNotificationActive: this.isSnackbarNotificationActive,
            clearNotifications: this.clearNotifications,
        };

        const notificationsOverlayProps: React.ComponentProps<typeof Grid> = {
            container: true,
            className: classnames(
                classes.notificationsOverlay,
                classes.overlayAnchorOriginTopRight,
            ),
            alignItems: 'flex-start',
            sx: {
                marginLeft: isNavDrawerOpen
                    ? `${UNIT * DRAWER_WIDTH_UNITS + UNIT * 3}px`
                    : `${UNIT * COLLAPSE_DRAWER_WIDTH_UNITS + UNIT * 3}px`,
            },
        };

        switch (showingType) {
            case NotificationsContextTypes.NotificationsShowingType.MOST_RECENT_BOTTOM:
                notificationsOverlayProps.direction = 'column-reverse';
                break;
            case NotificationsContextTypes.NotificationsShowingType.MOST_RECENT_TOP:
                notificationsOverlayProps.direction = 'column';
                break;
        }

        const needToFadeOutOnRemoving = this.needToFadeOutOnRemoving;

        return (
            <Grid
                className={classes.providerRoot}
                data-testid="provider-root"
                automation-id="MXDeposit-notifications-context-provider"
            >
                <Grid {...notificationsOverlayProps}>
                    {list.map((data: SnackbarNotificationData, index: number) => {
                        const {
                            type,
                            message,
                            descriptor,
                            index: notificationIndex,
                            timeoutMs,
                            className,
                        } = data;

                        const notificationWrapperProps: React.ComponentProps<typeof Grid> = {
                            key: notificationIndex,
                            item: true,
                            className: classnames(
                                classes.snackbarNotificationWrapper,
                                {
                                    [classes.mostRecentBottomNotificationWrapper]:
                                        showingType ===
                                        NotificationsContextTypes.NotificationsShowingType
                                            .MOST_RECENT_BOTTOM,
                                    [classes.mostRecentTopNotificationWrapper]:
                                        showingType ===
                                        NotificationsContextTypes.NotificationsShowingType
                                            .MOST_RECENT_TOP,
                                },
                                className?.notificationWrapper,
                            ),
                        };

                        if (data.isPendingForRemoving && needToFadeOutOnRemoving) {
                            notificationWrapperProps.className = classnames(
                                notificationWrapperProps.className,
                                classes.autoRemovingNotificationWrapper,
                            );
                            notificationWrapperProps.style = {
                                animationDuration: `${data.autoRemovingTimeoutMs}ms`,
                            };
                        } else if (linearFadingOut || !!timeoutMs) {
                            const opacity = this.getNotificationOpacity(index);
                            notificationWrapperProps.style = {
                                opacity,
                            };
                        }

                        return (
                            <Grid {...notificationWrapperProps}>
                                <Snackbar
                                    open
                                    className={classnames(
                                        classes.snackbarNotificationRoot,
                                        className?.snackbarNotificationRoot,
                                    )}
                                >
                                    <div>
                                        <Notification
                                            classes={{ notificationMessage: classes.notification }}
                                            message={message}
                                            type={type}
                                            closeCallback={() => {
                                                this.closeSnackbarNotification(
                                                    descriptor,
                                                    notificationIndex,
                                                );
                                            }}
                                        />
                                    </div>
                                </Snackbar>
                            </Grid>
                        );
                    })}
                </Grid>
                <NotificationsContext.Provider value={providerValue}>
                    {children}
                </NotificationsContext.Provider>
            </Grid>
        );
    }
}
export const NotificationsContextProvider = compose(
    connectToState,
    withStyles(styles),
    injectIntl,
)(NotificationsContextProviderBase) as React.FC<any>;

export const NotificationsContextConsumer = NotificationsContext.Consumer;
